<template>
  <!-- 实验用房预约 -->
  <div class="main-contain">
    <div class="head">
      <div class="head-title">{{ roominfo.lab_name }}</div>
      <div class="head-bt" @click="goback">返回</div>
    </div>
    <div class="line"></div>
    <!-- <div class="tip tip-style">{{tip}}</div> -->
    <div class="table-title">
      <div class="left">
        <div class="item">
          <div class="pos">地点:</div>
          <div class="posname">{{ roominfo.building_name }}</div>
        </div>
        <div class="item">
          <div class="pos">容量:</div>
          <div class="posname">{{ roominfo.station_num }}人</div>
        </div>
        <div class="item">
          <div class="pos">面积:</div>
          <div class="posname">{{ roominfo.area_size }}</div>
        </div>
      </div>
      <div class="right">
        <el-date-picker
          size="small"
          v-model="date"
          type="month"
          placeholder="选择月"
          @change="selectdate"
        >
        </el-date-picker>
      </div>
    </div>
    <div class="table-headbt">
      <div class="left"></div>
      <div class="center">实验用房预约</div>
      <div class="right">
        <div class="item">
          <div class="bt btactive"></div>
          <div class="bttext bttextactive">可预约</div>
        </div>
        <div class="item">
          <div class="bt"></div>
          <div class="bttext">不可预约</div>
        </div>
      </div>
    </div>
    <div class="table-head">
      <el-calendar v-model="timerange">
        <!-- 这里使用的是 2.5 slot 语法，对于新项目请使用 2.6 slot 语法-->
        <template slot="dateCell" slot-scope="{ date, data }">
          <el-button
            @click.stop="clickitem(data, date)"
            :disabled="!allowtime.includes(data.day)"
            class="tablecell"
            :class="allowtime.includes(data.day) ? 'tablecellallow ' : ''"
          >
            {{ data.day.split("-").slice(1).join("-") }}
          </el-button>
        </template>
      </el-calendar>
    </div>

    <Customdialog
      ref="customdialog"
      class="customdialog"
      width="50%"
      type="max"
      title="预约实验室"
      :showclose="true"
    >
      <div slot="dialogbody" class="dialogbody">
        <Table ref="table" v-if="flag" :closedialog="closedialog"></Table>
      </div>
      <div slot="dialogfooter" class="dialogfooter">
        <div class="bt" @click="closedialog">取消</div>
        <div class="bt btactive" @click="openroomreservation">确定</div>
      </div>
    </Customdialog>
  </div>
</template>
<script>
import Customdialog from "@/components/customdialog.vue";
import dayjs from "dayjs";
import Table from "./table";
import { openroombookgetdataList, openroombook } from "@/api/openroombook";
import { getOpenInfo } from "@/api/openroom";

export default {
  name: "courselearning",
  components: {
    Customdialog,
    Table,
  },
  data() {
    return {
      timerange: new Date(),
      allowtime: [],
      issbumit: false,
      roomid: "",
      roominfo: "",
      tip: "",
      date: "",
      activebtid: 0,
      componentId: "Roomintroduction",
      testimage: require("@/assets/test.png"),
      icon1: require("@/assets/coursedetails/图层 57.png"),
      flag: false,
    };
  },

  filters: {
    dataformat(value) {
      if (!value) return "";
      return dayjs.unix(value).format("YYYY-MM-DD HH:mm:ss");
    },
    dataformat2(value) {
      if (!value) return "";
      return dayjs(value).day();
    },
  },

  created() {
    this.tip = this.$route.query.tip;
    this.roomid = this.$route.query.id;
    this.getinfo();
    this.getdataList({
      open_room_id: this.$route.query.id,
      month: dayjs().format("YYYY-MM").split("-").join(""),
    });
  },
  mounted() {
    //自定义点击日历视图"前一个月"和 "后一个月"的点击事件
    this.$nextTick(() => {
      // 点击前一个月
      let prevBtn = document.querySelector(
        ".el-calendar__button-group .el-button-group>button:nth-child(1)"
      );
      prevBtn.addEventListener("click", () => {
        this.monthBtnhandler();
      });
    });
    this.$nextTick(() => {
      // 点击前今天
      let prevBtn = document.querySelector(
        ".el-calendar__button-group .el-button-group>button:nth-child(2)"
      );
      prevBtn.addEventListener("click", () => {
        this.monthBtnhandler();
      });
    });

    this.$nextTick(() => {
      // 点击后一个月
      let prevBtn = document.querySelector(
        ".el-calendar__button-group .el-button-group>button:last-child"
      );
      prevBtn.addEventListener("click", () => {
        this.monthBtnhandler();
      });
    });
  },
  methods: {
    getinfo() {
      let params = { id: parseInt(this.roomid) };
      getOpenInfo(params).then((response) => {
        this.roominfo = response.data;
      }).catch((error) => {
        console.log(error);
      });
    },
    filtertime(val) {
      let isinclude = this.allowtime.includes(val);
      return isinclude;
    },
    goback() {
      this.$router.push({
        path: "/home/openroom/roomdetails",
        query: {
          id: this.roomid,
        },
      });
    },
    selectdate(val) {
      this.timerange = val;
      this.getdataList({
        open_room_id: this.$route.query.id,
        month: dayjs(val).format("YYYY-MM").split("-").join(""),
      });
    },
    closedialog() {
      this.$refs.customdialog.dialogclosebt();
    },
    clickitem(data, date) {
      this.$refs.customdialog.dialogopenbt();
      this.flag = false;
      this.$nextTick(() => {
        this.flag = true;
        setTimeout(() => {
          this.$refs.table.initdata(
            this.roomid,
            this.roominfo,
            data,
            dayjs(date).day(),
            date
          );
        }, 1000);
      });
    },
    //开放用房预约
    openroomreservation() {
      this.$refs.table.submitbt();
    },
    getdataList(params) {
      this.allowtime = [];
      openroombookgetdataList(params)
        .then((response) => {
          if (response.code == 0) {
            if (response.data && response.data.length > 0) {
              this.allowtime = response.data;
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    paramsDateHandler(val) {
      let y = val.getFullYear();
      let m = val.getMonth() + 1;
      m = m * 1 >= 10 ? m : "0" + m;
      let paramsDate = y + "" + m;
      return paramsDate;
    },
    //月历视图的月份按钮点击事件
    monthBtnhandler() {
      let pd = this.paramsDateHandler(this.timerange);
      this.getdataList({
        open_room_id: this.$route.query.id,
        month: pd,
      });
    },
  },
};
</script>
<style scoped lang="scss">



.main-contain {
  padding-left: 10px;
  padding-right: 10px;
  .head {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .head-title {
      font-size: 20px;
      font-weight: 400;
      color: #3d84ff;
      user-select: none;
      white-space: nowrap;
      margin-left: 20px;
    }
    .head-bt {
      width: 64px;
      height: 38px;
      line-height: 38px;
      text-align: center;
      background: #3d84ff;
      border-radius: 4px;
      font-size: 18px;
      font-weight: 400;
      color: #ffffff;
      user-select: none;
      cursor: pointer;
      margin: 0px;
      padding: 0px;
      margin-right: 20px;
    }
  }
  .line {
    margin-top: 10px;
    border-bottom: 1px solid #cccccc;
  }
  .table-title {
    margin-top: 20px;
    height: 56px;
    background: #fcfcfc;
    border: 1px solid #ececec;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .left {
      padding-left: 30px;

      display: flex;
      justify-content: center;
      align-items: center;
      .item {
        margin-right: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        .pos {
          margin-right: 10px;
          font-size: 15px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #666666;
        }
        .posname {
          font-size: 15px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #222222;
        }
      }
    }
    .right {
      padding-right: 20px;
    }
  }
  .tip {
    margin-top: 10px;
    margin-bottom: 10px;
    height: 26px;
    background: #fffbe6;
    border: 1px solid #ffaa00;
    border-radius: 5px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .icon {
      margin-left: 20px;
      margin-right: 10px;
      width: 17px;
      height: 17px;
      line-height: 17px;
      text-align: center;
      border-radius: 50%;
      background: #ffaa00;
      color: white;
      .tiptext {
        font-size: 15px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #666666;
      }
    }
  }
  .tip-style {
    color: #ff0000;
    font-size: 12px;
    width: 100%;
    box-sizing: border-box;
    justify-content: center;
  }
  .table-headbt {
    height: 33px;
    background: #5584f7;
    border: 1px solid #5584f7;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .center {
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #ffffff;
    }
    .right {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .item {
        margin-right: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        .bt {
          margin-right: 4px;
          width: 27px;
          height: 16px;
          background: #ffffff;
        }
        .btactive {
          background: #baf0f0;
        }
        .bttext {
          font-size: 15px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #ffffff;
        }
        .bttextactive {
          color: #d2f5f5;
        }
      }
    }
  }
  .table-head {
    .tablecell {
      // border: 1px solid red;
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .tablecellallow {
      background: #baf0f0;
    }
    .is-selected {
      color: #1989fa;
    }
  }
  .customdialog {
    .dialogfooter {
      border-top: 1px solid #dedede;
      height: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .bt {
        margin-right: 20px;
        width: 64px;
        height: 38px;
        line-height: 38px;
        text-align: center;
        background: #ffffff;
        border: 1px solid #cccccc;
        border-radius: 2px;
        user-select: none;
        cursor: pointer;
        font-size: 14px;
        font-weight: 400;
        color: #cccccc;
      }
      .btactive {
        background: #3d84ff;
        color: #ffffff;
        border: 1px solid #3d84ff;
      }
    }
  }
}
</style>
