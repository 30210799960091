var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"eldialogbody"},[_c('div',{staticClass:"contain"},[_c('div',{staticClass:"left"},[_c('el-form',{ref:"form",attrs:{"rules":_vm.rules,"model":_vm.form,"label-width":"90px"}},[_c('el-form-item',{attrs:{"label":"预约人:"}},[_c('el-input',{staticClass:"elinput",attrs:{"disabled":""},model:{value:(_vm.userinfo.name),callback:function ($$v) {_vm.$set(_vm.userinfo, "name", $$v)},expression:"userinfo.name"}})],1),_c('el-form-item',{attrs:{"label":"实验室:"}},[_c('el-input',{staticClass:"elinput",attrs:{"disabled":""},model:{value:(_vm.roominfo.lab_name),callback:function ($$v) {_vm.$set(_vm.roominfo, "lab_name", $$v)},expression:"roominfo.lab_name"}})],1),_c('el-form-item',{attrs:{"label":"日期:"}},[_c('el-input',{staticClass:"elinput",attrs:{"disabled":""},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1),_c('el-form-item',{staticClass:"elinput",attrs:{"label":"预约要求:"}},[_c('el-radio-group',{model:{value:(_vm.roominfo.book_type),callback:function ($$v) {_vm.$set(_vm.roominfo, "book_type", $$v)},expression:"roominfo.book_type"}},[_c('el-radio',{attrs:{"label":1,"disabled":_vm.roominfo.book_type != 1}},[_vm._v("实验位")]),_c('el-radio',{attrs:{"label":2,"disabled":_vm.roominfo.book_type != 2}},[_vm._v("实验室")])],1)],1),_c('el-form-item',{attrs:{"label":"预约时间:"}},[(_vm.setting_list && _vm.setting_list.length > 0)?[_c('div',[_vm._v(" 请从以下时间段选择: "),_vm._l((_vm.setting_list),function(item,index){return _c('span',{key:index},[_vm._v(_vm._s(item.start_time + "~" + item.end_time + ","))])})],2)]:_vm._e(),_c('el-time-select',{staticClass:"elinput2",attrs:{"placeholder":"起始时间","picker-options":{
              start: '06:00',
              step: '00:30',
              end: '22:00',
            }},model:{value:(_vm.form.start_time),callback:function ($$v) {_vm.$set(_vm.form, "start_time", $$v)},expression:"form.start_time"}}),_vm._v(" 至 "),_c('el-time-select',{staticClass:"elinput2",attrs:{"placeholder":"结束时间","picker-options":{
              start: '06:00',
              step: '00:30',
              end: '22:00',
              minTime: _vm.form.start_time,
            }},model:{value:(_vm.form.end_time),callback:function ($$v) {_vm.$set(_vm.form, "end_time", $$v)},expression:"form.end_time"}})],2),_c('el-form-item',{staticClass:"elinput",attrs:{"label":"实验方案:"}},[_c('UploadFiles',{attrs:{"multiple":false,"files":_vm.fileList,"module":'open_room_book/plan',"cloudType":'openRoomBook/plan'},on:{"setUrl":_vm.getUrl}})],1),_c('el-form-item',{attrs:{"label":"实验说明:","prop":"remark"}},[_c('el-input',{staticClass:"elinput",attrs:{"type":"textarea","autosize":{ minRows: 4, maxRows: 8 },"placeholder":"请输入备注"},model:{value:(_vm.form.remark),callback:function ($$v) {_vm.$set(_vm.form, "remark", $$v)},expression:"form.remark"}})],1)],1)],1),_c('div',{staticClass:"centerline"}),_c('div',{staticClass:"right"},[_c('div',{staticClass:"title"},[_vm._v(" ◀ "+_vm._s(_vm.dataarr[0])+"年"+_vm._s(_vm.dataarr[1])+"月"+_vm._s(_vm.dataarr[2])+"日 星期"+_vm._s(_vm._f("weekformat")(_vm.week))+" ▶ ")]),_vm._m(0),_c('div',{staticClass:"chartcontain"},[_c('div',{staticClass:"maincontain"},[_vm._l((18),function(item){return _c('div',{key:item,staticClass:"item",style:({ top: -9 + (item - 1) * 60 + 'px' })},[_c('div',{staticClass:"num"},[_vm._v(_vm._s(_vm._f("formattime")((item - 1 + 6))))]),_c('div',{staticClass:"line"})])}),_vm._l((_vm.book_list),function(item,index){return _c('div',{key:index,staticClass:"area",style:({
              top: item.start_time - 360 + 'px',
              height: item.end_time - item.start_time + 'px',
            })})})],2)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tips"},[_c('div',{staticClass:"icons"}),_c('div',{staticClass:"tiptexts"},[_vm._v("已被预约")])])
}]

export { render, staticRenderFns }